/**
 * Site navigation JavaScript
 */
import event from "../../../../../../../clientlibs/v1/publish/js/utils/publisherSubscriber";

const searchOpener = document.querySelector(".search-opener");
const quicksearchfield = document.querySelector('#quicksearch-input');
const mobileNavOpener = document.querySelector(".mobile-nav-opener");
const mainNav = document.querySelector("#main-navigation");
const headerSearch = document.querySelector(".site-search");
const mainPageContainer = document.querySelector(".container");
const primaryNavItems = document.querySelectorAll(".nav-level--0 > span.primary > span.icon-chevron");
const secondaryNavItems = document.querySelectorAll(".nav-level--1 > span.secondary > span.icon-plus");

let isTouchDevice = document.getElementsByTagName("body")[0].classList.contains("touch-device");

let findParentEL = (rootel, findelclass) => {
    while (rootel.parentNode) {
        rootel = rootel.parentNode;
        if (rootel.classList.contains(findelclass))
            return rootel;
    }
    return null;
}

export default (function buildHeader() {
    if (mainNav) {
        let deviceClass = (isDesktop) => {
            isDesktop.isDesktop == true || isDesktop == true
                ? (document.querySelector("body").classList.add("desktop"),
                    document.querySelector("body").classList.remove("mobile"),
                    subMenuTouch(isDesktop.isDesktop, isTouchDevice))
                : (document.querySelector("body").classList.remove("desktop"),
                    document.querySelector("body").classList.add("mobile"),
                    subMenuTouch(isDesktop.isDesktop, isTouchDevice));
        };

        window.onload = () => {
            document.querySelectorAll("body")[0].classList.contains("mobile") ? deviceClass(false) : deviceClass(true);
        };

        let subMenuTouch = (isDesktop, isTouchDevice) => {
            addRemoveNavTouchEvents("primary", primaryNavItems, isDesktop, isTouchDevice);

            addRemoveNavTouchEvents("secondary", secondaryNavItems, isDesktop, isTouchDevice);
        };

        let processChildUls = (item, childUls) => {
            item.addEventListener("click", (event) => {
                event.stopImmediatePropagation();
                addRemoveAriaExpanded(item);
                // preventdefault cant exactly be undone
                item.onclick = () => {
                    return false;
                };

                childUls.classList.contains("open")
                    ? (childUls.classList.remove("open"), childUls.parentElement.classList.remove("open-child"))
                    : (item.parentElement.parentElement.parentElement.querySelectorAll("ul.open").forEach((item) => {
                        item.classList.remove("open");
                        item.parentElement.classList.remove("open-child");
                    }),
                        childUls.classList.add("open"),
                        childUls.parentElement.classList.add("open-child"));
            });
        };

        let addRemoveAriaExpanded = (whatelment, whatvalue) => {
            whatelment.setAttribute("aria-expanded", whatelment.getAttribute("aria-expanded") == "true" ? "false" : "true");
        };
        let addRemoveNavTouchEvents = (id, navItems, isDesktop, isTouchDevice) => {
            if (isTouchDevice) {
                navItems.forEach((item) => {
                    item.setAttribute("aria-expanded", false);
                    item.setAttribute(
                        "aria-controls",
                        item.parentElement.parentElement.querySelector("ul").getAttribute("id")
                    );
                    let itemsChildUl = item.parentElement.parentElement.querySelector(".submenu-holder");
                    let itemsGrandchildChildUl = itemsChildUl.parentElement.querySelectorAll("ul")[0];
                    if (id === "primary" && itemsChildUl !== undefined) {
                        processChildUls(item, itemsChildUl);
                    }
                    if (id === "secondary" && itemsGrandchildChildUl !== undefined) {
                        processChildUls(item, itemsGrandchildChildUl);
                    }
                });
            } else {
                // it is a desktop, so you can do something else here
            }
        };

        // search opener
        searchOpener.addEventListener(
            "click",
            (event) => {
                headerSearch.classList.toggle("show");
                nudgeNavSearch();
                if(quicksearchfield != null){
                	quicksearchfield.focus();
                }
            },
            false
        );

        if(quicksearchfield != null){
	        quicksearchfield.addEventListener('blur', (event) => {
	            // headerSearch.classList.remove("show");
	        })
        }

        // mobile nav opener
        mobileNavOpener.addEventListener(
            "click",
            (event) => {
                mainNav.classList.toggle("show");
                nudgeNavSearch();
            },
            false
        );
        /**
         * STARTED FIX FOR SHOW HIDE TERTIARY NAV ON MOB
        document.querySelectorAll('.secondary > a:first-child').forEach(item =>{ item.appendChild("<span class='icon-chevron'></span>"); });
        document.querySelector('.secondary > a:first-child').addEventListener('click', e => {
            e.currentTarget.parent().classList.toggle('open-nav-level--2');
        })
        **/
        // check if search AND nav is open at mobile
        // if so move the nav down to prevent search covering the nav
        let nudgeNavSearch = () => {
            if (
                document.getElementById("main-navigation").classList.contains("show") &&
                document.getElementById("site-search").classList.contains("show")
            ) {
                document.getElementById("main-navigation").classList.add("search-is-open");
            } else {
                document.getElementById("main-navigation").classList.remove("search-is-open");
            }
        };

        document.querySelectorAll('.nav-level--0.parent .primary .icon-chevron').forEach(a => {
            a.addEventListener('click', e => {
                findParentEL(e.target, 'nav-level--0').querySelector('.submenu-holder').classList.toggle('sub-nav-open');
                findParentEL(e.target, 'nav-level--0').querySelector('.icon-chevron').classList.toggle('open');
            })
        });

        document.querySelectorAll('.nav-level--1.parent .secondary .icon-chevron').forEach(a => {
            a.addEventListener('click', e => {
                findParentEL(e.target, 'nav-level--1').querySelector('.sub-submenu-holder').classList.toggle('sub-sub-nav-open');
                findParentEL(e.target, 'nav-level--1').querySelector('.icon-chevron').classList.toggle('open');
            })
        });

        /** accessibility aspects - wip  */
        /*
        document.querySelectorAll('.nav-level--0.parent .primary a').forEach(a => {
            a.addEventListener('click', (e) => {
            if (e.currentTarget.parentElement.parentElement.classList.contains('allow-enter')) { } else { e.preventDefault();}
         })
        })

        document.querySelectorAll('.nav-level--0.parent').forEach( li => {
            li.addEventListener('keyup', (event) => {
                if (event.key == 'Enter') {
                    if (event.currentTarget.classList.contains('allow-enter')) {
                        event.currentTarget.classList.remove('allow-enter')
                        event.currentTarget.classList.remove('hover');
                    } else {
                        event.currentTarget.classList.add('allow-enter')
                        event.currentTarget.classList.add('hover');
                    }
                }
                if (event.key == "Escape") {
                    event.currentTarget.classList.add('hover');
                }
                li.onblur = (e, f) => item.currentTarget.classList.remove('hover');
            })
        });
    */
        // document.querySelectorAll('.nav-level--0.parent .primary a').forEach( a => {
        // a.onfocus = (e, f) => {
        //     console.log('main nav a focus');
        //     if (e.currentTarget.parentElement.parentElement.previousSibling) {
        //         if (e.currentTarget.parentElement.parentElement.previousSibling.contains("hover")) {
        //         e.currentTarget.parentElement.parentElement.previousSibling.classList.remove("hover");
        //         }
        //     }
        //     if (e.currentTarget.parentElement.parentElement.nextSibling && e.currentTarget.parentElement.parentElement.nextSibling.contains("hover")) {
        //         e.currentTarget.parentElement.parentElement.nextSibling.classList.remove("hover")
        //     }
        // };
        // a.onblur = (e, f) => {
        //     console.log('main nav a blur');
        //         e.currentTarget.parentElement.parentElement.classList.remove("hover")
        // };
        // })
        // accessibility - to finish
        // document.querySelectorAll("#main-navigation a").forEach((el) => {
        //     console.log('main nav a');
        //     if (!isTouchDevice) {
        //     el.onfocus = (e, f) => {
        //         console.log('main nav a focust');

        //         findParentEL(e.target, 'nav-level--0').previousSibling.previousSibling
        //             ? findParentEL(e.target, 'nav-level--0').previousSibling.previousSibling.classList.remove("focus")
        //             : null;
        //             findParentEL(e.target, 'nav-level--0').nextSibling.nextSibling
        //             ? findParentEL(e.target, 'nav-level--0').nextSibling.nextSibling.classList.remove("focus")
        //             : null;
        //         findParentEL(e.target, 'nav-level--0').classList.add("focus");


        //         document.querySelector('li.focus').addEventListener('keydown', (event) => {
        //             if (event.key == "Enter") {
        //                 event.currentTarget.classList.add('hover');
        //                 event.currentTarget.classList.add('allow-enter');

        //             event.currentTarget.querySelector('a').addEventListener('click', (e) => { e.preventDefault(); });
        //                 document.querySelector('li.focus.allow-enter a').addEventListener('click', (e) => { window.location.href = e.target.getAttribute('href') })
        //             }
        //             if (event.key == "Escape") {
        //                 event.currentTarget.classList.remove('hover');
        //                 event.currentTarget.classList.remove('allow-enter');
        //             }

        //         });

        //     };
        // }
        //     el.onmouseover = (e, f) => {
        //         console.log('main nav a hover');
        //         findParentEL(e.target, 'nav-level--0').previousSibling.previousSibling
        //             ? findParentEL(e.target, 'nav-level--0').previousSibling.previousSibling.classList.remove("hover")
        //             : null;
        //         findParentEL(e.target, 'nav-level--0').nextSibling.nextSibling
        //             ? findParentEL(e.target, 'nav-level--0').nextSibling.nextSibling.classList.remove("hover")
        //             : null;
        //         findParentEL(e.target, 'nav-level--0').classList.add("hover");
        //     };
        //     el.onblur = (e, f) => {
        //         console.log('main nav a blur');
        //         findParentEL(e.target, 'nav-level--0').classList.contains("focus") //&& !findParentEL(e.target, 'nav-level--0').classList.contains("parent")
        //         ?
        //         findParentEL(e.target, 'nav-level--0').classList.remove("focus") : null
        //     };
        //     el.onmouseout = (e, f) => {
        //         console.log('main nav a unhover');
        //         findParentEL(e.target, 'nav-level--0').classList.contains("hover") ?
        //         findParentEL(e.target, 'nav-level--0').classList.remove("hover") : null
        //     };
        // });

        // // global catcher to close search
        // mainPageContainer.addEventListener("click", () => {
        //     headerSearch.classList.remove("show");
        //     if (document.querySelector("#main-navigation li.hover")) document.querySelector("#main-navigation li.hover").classList.remove('hover');
        // });

        // Subscribe to viewport events
        // Receive initial viewport information
        event.subscribe("/mediaquery/loaded", (data) => {
            deviceClass(data);
        });

        // Receive notification of viewport change from mobile to desktop, or vice versa
        // Ignore other registered breakpoint changes
        event.subscribe("/mediaquery/crossover", (data) => {
            deviceClass(data);
        });
    }
})();
