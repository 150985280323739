import { sortByString } from "../../../../../../../clientlibs/v1/publish/js/utils/array";
import { isElement } from "../../../../../../../clientlibs/v1/publish/js/utils/element";
import cookies, { getRegionCookieName } from "../../../../../../../clientlibs/v1/publish/js/utils/cookies";

const form = document.getElementById("region-form");
const selectRegion = document.getElementById("select_region");
const selectLanguage = document.getElementById("select_language");
const selectType = document.getElementById("select_investor_type");
const useCookieName = "SIW_COOKIES_ACCEPTED";
const globalRegion = "GLOBAL";

let data = {};

function getData() {
    return JSON.parse(form.getAttribute("data-model") || {});
}

function getPlaceholder(element, dataSet) {
    const placeholderString = element.getAttribute("data-placeholder");

    if (!placeholderString || dataSet.length === 1) {
        return [];
    }

    return [
        {
            name: placeholderString,
            keyId: ""
        }
    ];
}

function setSubmitStatus() {
    const submit = form.querySelector("[type=submit]");
    const values = [selectRegion, selectLanguage, selectType].filter((select) => select.value);
    submit.disabled = !(values.length === 3);
}

function setOptions(element, dataSet) {
    const dataSetSorted = sortByString(
        Object.keys(dataSet).map((key) => {
            return {
                ...dataSet[key],
                keyId: key
            };
        }),
        "name"
    );

    // Create options array with placeholder item, as necessary
    const options = getPlaceholder(element, dataSetSorted).concat(dataSetSorted);

    element.innerHTML = options
        .map((item) => {
            const { keyId, name } = item;
            return `<option value="${keyId}">${name}</option>`;
        })
        .join("");
}

function setRegionSelect(event = {}) {
    if (event.target === selectRegion && !selectRegion.value) {
        form.reset();
        setOptions(selectLanguage, {});
        setOptions(selectType, {});
    }
}

function setLanguageSelect(event = {}) {
    if (selectRegion.value && event.target !== selectLanguage) {
        const { languages } = data[selectRegion.value];
        setOptions(selectLanguage, languages);
    }
}

function setInvestorTypeSelect(event = {}) {
    const region = selectRegion.value;
    const language = selectLanguage.value;

    if (region && language) {
        const { investorTypes } = data[region].languages[language];
        if (event.target !== selectType) {
            setOptions(selectType, investorTypes);
        }
    }
}

function setFilterOptions() {
    const filterOptions = form.querySelector(".filter-options");

    setOptions(selectRegion, data);
    selectLanguage.closest("p").hidden = false;
    selectType.closest("p").hidden = false;

    filterOptions.addEventListener(
        "change",
        function changeEvent(event) {
            setRegionSelect(event);
            setLanguageSelect(event);
            setInvestorTypeSelect(event);
            setSubmitStatus();
        },
        false
    );
}

function formSubmitEvent() {
    form.addEventListener(
        "submit",
        function submitEvent(event) {
            const result = data[selectRegion.value].languages[selectLanguage.value].investorTypes[selectType.value];
            var regionCookieName = getRegionCookieName();
            cookies.set(regionCookieName, result.path, { "max-age": 1209600 }); // Expires in 14 days
            cookies.set('SIW_REGION_SELECTED', true);
            event.preventDefault();
            // Target window.top, as we may be in a modal/iframe
            window.top.location = result.path;
        },
        false
    );
}

export default (function createRegionForm() {
    if (isElement(form)) {
        data = getData();
        formSubmitEvent();
        setFilterOptions();
        setLanguageSelect();
        setInvestorTypeSelect();
        setSubmitStatus();
    }
})();

(function checkRegionCookie(){
    if(SIW && SIW.region.toUpperCase() === globalRegion && cookies.get(useCookieName)){
        var regionCookie = cookies.get(getRegionCookieName());
        var regEx = new RegExp('/' + globalRegion + '/', 'i');
        if(regionCookie && !regEx.test(regionCookie)){
            window.top.location = regionCookie;
        }
    }
})();
