export function addTransitionEndEvent(element, fn = () => {}) {
    ["webkitTransitionEnd", "transitionend"].forEach((transition) => {
        element.addEventListener(transition, fn, false);
    });
}

export function isElement(element) {
    return element instanceof Element;
}

export function getAttributes(element) {
    const attributes = {};
    if (isElement(element)) {
        for (let i = 0; i < element.attributes.length; i += 1) {
            const { nodeName, nodeValue } = element.attributes[i];
            attributes[nodeName] = nodeValue;
        }
    }
    return attributes;
}

function hasOverflow(element, direction) {
    const prop = direction.toLowerCase() === "width" ? "Width" : "Height";
    return element[`scroll${prop}`] > element[`client${prop}`];
}

export function widthOverflow(element) {
    return hasOverflow(element, "width");
}

export function heightOverflow(element) {
    return hasOverflow(element, "height");
}

export function getPosition(element) {
    const position = {
        top: 0,
        left: 0
    };

    if (element.offsetParent) {
        while (element) {
            position.top += element.offsetTop;
            position.left += element.offsetLeft;
            element = element.offsetParent;
        }
    }

    return position;
}

export default {
    addTransitionEndEvent,
    getAttributes,
    getPosition,
    heightOverflow,
    isElement,
    widthOverflow
};
