/**
 * WAI ARIA attribute utilities
 * ============================
 * Convenience functions to handle common `aria-n` attribute tasks
 *
 * Public methods index
 * --------------------
 * See individual methods for more information.
 *
 * - getControls(CONTROL_OR_TARGET_ELEMENT)
 * - setControls(CONTROL_ELEMENT, TARGET_ELEMENT)
 * - removeControls(CONTROL_OR_TARGET_ELEMENT)
 * - setCollapsed(CONTROL_OR_TARGET_ELEMENT, OPTIONAL_CALLBACK)
 * - setExpanded(CONTROL_OR_TARGET_ELEMENT, OPTIONAL_CALLBACK)
 *
 */

// APIs
import serialMaker from "./serialMaker";

// Utils
import { createElement, isElement } from "./element";
import { getRandomNumber } from "./math";

/**
 * Generate unique ids for target elements
 * Used by setControls() to bind trigger & target elements together
 * @param   {Object} config  - Options & default settings
 * @return  {String}
 */
function setSequence(config = { prefix: "item" }) {
    const serialId = serialMaker(config);
    serialId.setSequence(getRandomNumber(100000, 999999));
    return serialId.generate();
}

/**
 * Return target element referenced by control's `aria-controls` attribute
 * @param   {Element}  control  - Trigger element, i.e. `<button>`
 * @return  {Element}           - Target element referenced by control parameter
 */
function getTargetByAttribute(control) {
    return document.getElementById(control.getAttribute("aria-controls"));
}

/**
 * Return control element referenced by target's `id` attribute
 * @param   {Element}  target  - Expandable element, i.e. `<div id="UNIQUE _ID"></div>`
 * @return  {Element}          - Trigger element, i.e. `<button>`
 */
function getControlById(target) {
    return document.querySelector(`[aria-controls="${target.getAttribute("id")}"]`);
}

/**
 * Find an element's `aria-control` paired element, either by:
 * 1. Button `aria-controls` attribute value to target `id`, or;
 * 2. Target `id` attribute value to button `[aria-controls]`
 *
 * @param   {Element}  element -  Either the trigger button or target expandable element
 * @return  {Object}
 */
export function getControls(element) {
    if (!isElement(element)) {
        return {};
    }

    const TARGET = getTargetByAttribute(element);
    const CONTROL = getControlById(element);

    if (TARGET) {
        return {
            control: element,
            target: TARGET
        };
    } else if (CONTROL) {
        return {
            control: CONTROL,
            target: element
        };
    }

    // If you get this far something is wrong with your setup, or
    // there is a race condition in your code that needs fixing.
    //
    // Why return a dummy elements?
    // So that subsequent element method calls won't fail,
    // e.g. when calling Panel utility
    return {
        control: createElement("div"),
        target: createElement("div"),
        error: true
    };
}

export function setControls(control, target, config = {}) {
    if (!isElement(control) || !isElement(target)) {
        return;
    }

    const targetId = target.getAttribute("id");
    const id = targetId || setSequence(config);

    if (!targetId) {
        target.setAttribute("id", id);
    }

    control.setAttribute("aria-controls", id);
    control.setAttribute("aria-expanded", false);
}

export function removeControls(element) {
    const { control, target } = getControls(element);

    if (!target) {
        return;
    }

    control.removeAttribute("aria-controls");
    control.removeAttribute("aria-expanded");
}

function setAriaExpandedStatus(expandedValue, element, callback = () => {}) {
    const CONTROLS = getControls(element);
    const { control, target } = CONTROLS;

    if (!target) {
        return {};
    }

    control.setAttribute("aria-expanded", expandedValue);
    callback.call(this, {
        control,
        target
    });

    return CONTROLS;
}

export function setCollapsed(element, callback = () => {}) {
    return setAriaExpandedStatus(false, element, callback);
}

export function setExpanded(element, callback = () => {}) {
    return setAriaExpandedStatus(true, element, callback);
}

export default (function A11y() {
    return {
        getControls,
        setControls,
        removeControls,
        setCollapsed,
        setExpanded
    };
})();
