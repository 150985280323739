/**
 * Publisher/Subscriber (PubSub)
 * =============================
 * Model for broadcasting/subscribing to custom events
 * H/T: https://davidwalsh.name/pubsub-javascript
 *
 * Create a custom event
 * ---------------------
 * event.publish("/page/load", {
 *     // ...any arguments, e.g.
 *     url: "/some/url/path"
 * });
 *
 * Subscribe to custom event
 * -------------------------
 * var subscription = event.subscribe("/page/load", function(data) {
 *     // Do something now that the event has occurred
 * });
 *
 * Unsubscribe from custom event
 * -----------------------------
 * subscription.remove();
 */

function event() {
    const topics = {};
    const { hasOwnProperty } = topics;

    function publish(topic, data) {
        // If the topic doesn't exist, or there's no listeners in queue, just leave
        if (!hasOwnProperty.call(topics, topic)) {
            return;
        }
        // Cycle through topics queue, fire!
        topics[topic].map((item) => {
            return item(data !== undefined ? data : {});
        });
    }

    function remove(topic, index) {
        // Provide handle back for removal of topic
        return {
            remove: () => delete topics[topic][index]
        };
    }

    function subscribe(topic, listener) {
        // Create the topic's object if not yet created
        if (!hasOwnProperty.call(topics, topic)) {
            topics[topic] = [];
        }
        // Add the listener to queue
        const index = topics[topic].push(listener) - 1;
        return remove(topic, index);
    }

    return {
        publish,
        subscribe
    };
}

export default event();
