/**
 * Modal settings:
 * - ariaLabel:
 *      A descriptive label to help identify the purpose of the modal overlay for improved accessibility
 * - lock:
 *      When set to `true` no visible close button, user can't click on mask to close overlay
 *      There must be some action/process in the content that exits the overlay, otherwise this is an anti-pattern
 */

import { isElement } from "../../../../../../../clientlibs/v1/publish/js/utils/element";
import debounce from "../../../../../../../clientlibs/v1/publish/js/utils/debounce";
import tabTrap from "../../../../../../../clientlibs/v1/publish/js/utils/tab-trap";

const body = document.querySelector("body");
const mdl = document.querySelector("[data-modal]");
const page = document.querySelector("[data-modal-background]");
const css = {
    modalOpen: "modal-open",
    modalCloseable: "modal-closeable"
};
const modals = [];

let elements = {};
let openModal = null;
let scrollPositionY = 0;
let trapTabbing = null;

const resizeWindow = debounce((event) => {
    const iframe = elements.content.querySelector("iframe");
    if (iframe && openModal) {
        setIframeHeight(iframe, event);
    }
});

function setElements() {
    return {
        modal: mdl,
        mask: mdl.parentNode,
        content: mdl.querySelector(".modal-body"),
        closebutton: mdl.querySelector(".modal-button-close"),
        fullPageLink: mdl.querySelector(".modal-full-page-link")
    };
}

function getSourceId(source) {
    return isElement(source) && source.hasAttribute("href") ? source.getAttribute("href") : String(source);
}

function setModal(modalSettings = {}) {
    const { id } = modalSettings;
    if (!getModal(id)) {
        // No other modals with that ID exist, so add it
        modals.push(modalSettings);
    }
}

function getModal(id) {
    return modals.find((m) => m.id === id);
}

// Prevents double scrollbars appearing
// Scroll behaviour should only be on modal-body, so content can scroll properly on touch devices
function setIframeHeight(iframe, event) {
    const { content } = elements;
    const { type } = event;
    const loadedCss = "loaded";
    const iframeBody = iframe.contentWindow.document.body;

    // Allow height to reset
    iframe.style.height = "auto";

    // Async (slight hack) forces IE11 to behave with calculations
    // Same goes for the (repeated) height calculation on "load" event type below
    // Nasty, but apparently necessary
    setTimeout(() => {
        iframe.style.width = `${content.offsetWidth}px`;
        iframe.style.height = `${iframeBody.scrollHeight}px`;
    }, 0);

    if (type === "load") {
        // Repeat height calculation ensuring no double scrollbars
        iframe.style.height = `${iframeBody.scrollHeight}px`;
        iframe.classList.add(loadedCss);
    }
}

function setIframeContent(modal) {
    const { id, iframe } = modal;

    if (isElement(iframe)) {
        return iframe;
    }

    const element = document.createElement("iframe");
    element.addEventListener("load", (event) => setIframeHeight(element, event), false);
    element.setAttribute("src", `${id}${document.location.search}`);

    return element;
}

function detectEscKey(e) {
    const { lock } = openModal.options;
    const keyValue = e.key.toUpperCase();
    if (!lock && (keyValue == "ESC" || keyValue == "ESCAPE")) {
        close();
    }
}

function toggleCloseBehavior(config) {
    const { closebutton, mask, modal } = elements;
    const { lock } = config.options;
    if (!lock) {
        closebutton.hidden = !closebutton.hidden;
        modal.classList.toggle(css.modalCloseable);
        mask[closebutton.hidden ? "removeEventListener" : "addEventListener"]("click", close, false);
    }
}

function open(id) {
    const { mask } = elements;
    const modalConfig = getModal(id);

    scrollPositionY = window.scrollY;
    body.style.top = `${scrollPositionY * -1}px`;
    setContent(modalConfig);

    window.addEventListener("resize", resizeWindow, false);

    if (!openModal) {
        openModal = modalConfig;
        toggleCloseBehavior(modalConfig);
        mask.hidden = false;
    }

    // Quick solution to allow animation to fire
    setTimeout(() => {
        page.setAttribute("aria-hidden", true);
        body.classList.add(css.modalOpen);
        trapTabbing = tabTrap("[data-modal]");
    }, 250);
}

function close(id) {
    const { mask } = elements;
    const modalConfig = openModal || getModal(id);
    const { trigger } = modalConfig;

    body.style.top = "";
    body.classList.remove(css.modalOpen);
    page.removeAttribute("aria-hidden");
    window.scrollTo(0, scrollPositionY || 0);
    window.removeEventListener("resize", resizeWindow, false);

    if (openModal) {
        if (trigger) {
            trigger.focus();
        }
        trapTabbing.remove();
        toggleCloseBehavior(modalConfig);
        openModal = null;
    }

    // Quick solution to allow animation to fire
    setTimeout(() => {
        mask.hidden = true;
    }, 250);
}

function setContent(config) {
    const { content, fullPageLink, modal } = elements;
    const { options } = config;

    if (config) {
        config.iframe = setIframeContent(config);
        fullPageLink.setAttribute("href", `${config.id}${document.location.search}`);

        if (options.ariaLabel) {
            modal.setAttribute("aria-label", options.ariaLabel);
        } else {
            modal.removeAttribute("aria-label");
        }

        content.innerHTML = "";
        content.appendChild(config.iframe);
    }
}

function initModal() {
    const { closebutton, modal } = elements;
    // One-time setup
    if (modal.dataset.modal !== "initialized") {
        modal.addEventListener("keydown", detectEscKey, false);
        closebutton.addEventListener("click", close, false);
        modal.dataset.modal = "initialized";
    }
}

export default function createModal(triggerElementOrSourceString, modalOptions) {
    const modalAvailable = isElement(mdl) && !document.body.classList.contains("tmpl-blank");
    const id = getSourceId(triggerElementOrSourceString);
    const fn = () => {};

    if (modalAvailable) {
        elements = setElements();

        setModal({
            id,
            iframe: null,
            options: modalOptions,
            trigger: isElement(triggerElementOrSourceString) ? triggerElementOrSourceString : null
        });

        initModal();
    }

    return {
        close: modalAvailable ? () => close.call(null, id) : fn,
        open: modalAvailable ? () => open.call(null, id) : fn
    };
}
