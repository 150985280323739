import cookies, { getTermsCookieName } from "../../../../../../../clientlibs/v1/publish/js/utils/cookies";
import { isElement } from "../../../../../../../clientlibs/v1/publish/js/utils/element";
import event from "../../../../../../../clientlibs/v1/publish/js/utils/publisherSubscriber";

const importantInfo = document.querySelector("[data-important-info]");

export default (function createImportantInfo() {
    if (isElement(importantInfo)) {
        const termsCookieName = getTermsCookieName();
        const buttonAccept = importantInfo.querySelector("button[data-accept]");
        const buttonDecline = importantInfo.querySelector("button[data-decline]");

        buttonAccept.addEventListener(
            "click",
            (e) => {
                e.preventDefault();
                cookies.set(termsCookieName, 1, { "max-age": 2592000 }); // Expires at 30 days
                event.publish("/important/terms", { accepted: true });
            },
            false
        );

        buttonDecline.addEventListener(
            "click",
            (e) => {
                e.preventDefault();
                cookies.expire(termsCookieName);
                // Fire terms event with decline data
                event.publish("/important/terms", { accepted: false });
            },
            false
        );
    }
})();
