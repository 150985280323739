import { nodeListArray } from "../../../../../../../clientlibs/v1/publish/js/utils/dom";
import { getRandomNumber } from "../../../../../../../clientlibs/v1/publish/js/utils/math";
import event from "../../../../../../../clientlibs/v1/publish/js/utils/publisherSubscriber";
import serialMaker from "../../../../../../../clientlibs/v1/publish/js/utils/serialMaker";

const enhancedCss = "enhanced";
const accordionSelector = "data-accordion";
const firstOpenSelector = "data-open-first";
const panelSelector = "data-accordion-panel";
const triggerSelector = "data-accordion-trigger";

// SerialMaker/A11y:
// Used for creating random (hopefully unique) values to tie accordion expandable
// panel `id` attribute with its trigger button `aria-controls` value.
// Required if accordion trigger and expandable content are not sibling elements
const serialId = serialMaker({ prefix: "accordion-item" });

function createButton(trigger, labels = {}) {
    const text = trigger.textContent.trim();
    const ariaLabel = `aria-label="${labels.open}"`;

    return `
        <button ${!text ? ariaLabel : ""} aria-controls="${serialId.generate()}" aria-expanded="false">
            ${text}
            <span class="icon-plus"></span>
        </button>
    `;
}

function removeButton(trigger) {
    return trigger.textContent.trim();
}

function enhanceTriggersWithButtons(triggers, labels = {}) {

    return triggers.map((trigger) => {
        trigger.innerHTML = createButton(trigger, labels);
        trigger.closest(`[${panelSelector}]`).classList.add(enhancedCss);
        return trigger.querySelector("button");
    });
}

function prepareContentPanels(triggers) {
	let trigLength = triggers.length;
    return triggers.map((trigger) => {
        const button = trigger.querySelector("button");
        const target = trigger.closest('[data-accordion-panel]');
        target.setAttribute("id", button.getAttribute("aria-controls"));
        let selector = target.querySelectorAll('.content').length > 0 ? target.querySelector('.content') : target.querySelector('.accordion-content')
        if(trigLength > 0){
        		selector.hidden = true;
        }
        return target;
    });
}

function reevaluatePicturefill(target) {
    const images = nodeListArray(target.querySelectorAll("img"));
    if (images.length) {
        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            window.picturefill({
                reevaluate: true,
                elements: images
            });
        }, 180);
    }
}

function togglePanel(button, target, config = {}) {
    const { eventName, labels = {} } = config;
    const text = button.textContent.trim();
    let expanded;

    if (!eventName) {
        expanded = button.getAttribute("aria-expanded") === "true" || false;
        if (!text) {
            button.setAttribute("aria-label", expanded ? labels.open : labels.close);
        }
    } else {
        expanded = !(eventName === "open");
    }

    button.setAttribute("aria-expanded", !expanded);
    let selector = target.querySelectorAll('.content').length > 0 ? target.querySelector('.content') : target.querySelector('.accordion-content')
    selector.hidden = expanded;
    event.publish("/accordion/action", { opened: !expanded, target });
}

function openPanel(button, target, config = {}) {
    togglePanel(button, target, {
        labels: config.buttonLabels,
        eventName: "open"
    });
}

function closePanel(button, target, config = {}) {
    togglePanel(button, target, {
        labels: config.buttonLabels,
        eventName: "close"
    });
}

export function initAccordion(accordion, config = {}) {
    serialId.setSequence(getRandomNumber(100000, 999999));
    const accordionType = accordion.getAttribute(accordionSelector);
    const isFirstOpen = accordion.getAttribute(firstOpenSelector);
    const triggers = nodeListArray(accordion.querySelectorAll(`[${triggerSelector}]`));
    const buttons = enhanceTriggersWithButtons(triggers, config.buttonLabels);
    const targets = prepareContentPanels(triggers);

    if (isFirstOpen) {
        openPanel(buttons[0], targets[0], config);
    }

    buttons.forEach((button, index) => {
        const currentTarget = targets[index];
        button.addEventListener("click", (e) => {
            if (accordionType === "single") {
                // Close all panels
                buttons.forEach((btn, i) => closePanel(btn, targets[i], config));
                // Open the one selected panel
                openPanel(button, currentTarget, config);
            } else {
                togglePanel(button, currentTarget, {
                    labels: config.buttonLabels,
                    eventName: null
                });
            }
            e.preventDefault();
        },
            false
        );

        event.subscribe("/accordion/action", (data) => {
            const { opened, target } = data;
            if (opened && target === currentTarget) {
                reevaluatePicturefill(target);
            }
        });
    });
}

export function tearDownAccordion(accordion) {
    const triggers = nodeListArray(accordion.querySelectorAll(`[${triggerSelector}]`));
    triggers.forEach((trigger) => {
        const target = trigger.nextElementSibling;
        trigger.innerHTML = removeButton(trigger);
        trigger.closest(`[${panelSelector}]`).classList.remove(enhancedCss);
        target.removeAttribute("id");
        target.hidden = false;
    });
}

export default (function createAccordions() {
    if (window.SIW && !window.SIW.isAuthor) {
        const accordions = nodeListArray(document.querySelectorAll(`[${accordionSelector}]`));
        accordions.forEach((accordion) => initAccordion(accordion));
    }
})();
