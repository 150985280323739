/**
 * Google Maps JavaScript
 * Documentation:
 * - https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/javascript/examples
 * - https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/javascript/controls#ControlOptions
 * - https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/javascript/examples/marker-simple
 */

import { loadScript, nodeListArray } from "../../../../../../../clientlibs/v1/publish/js/utils/dom";

// @improvement:
// Store the api key somewhere global in the SIW CMS, so changing key would not require code deployment
const apiKey = "AIzaSyB_38Mll0rJcuqOioalE6XCx0e1Jk7Zrlg";
const containerElement = ".googlemaps-container";
const mapElement = ".google-map";

const mapTypeControlOptions = {
    mapTypeIds: ["roadmap"]
};

function getMaps() {
    return nodeListArray(document.querySelectorAll(mapElement));
}

function setStyles() {
    const hideFeatures = ["poi.attraction", "poi.business", "poi.government", "poi.sports_complex"];
    return hideFeatures.map((feature) => {
        return {
            featureType: feature,
            stylers: [{ visibility: "off" }]
        };
    });
}

function createMarker(map, coords, title) {
    return new google.maps.Marker({
        position: coords,
        map: map,
        title: title || null
    });
}

function createInfoWindow(map, marker, body) {
    const contentString = [
        `<div id="content">`,
        `<div id="siteNotice"></div>`,
        body ? `<div id="bodyContent">${body}</div>` : "",
        `</div>`
    ].join("");

    const infowindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 280
    });

    marker.addListener("click", function openInfoWindow() {
        infowindow.open(map, marker);
    });
}

function createGoogleMap(element) {
    const container = element.closest(containerElement);
    const data = element.dataset;
    const controls = JSON.parse(data.controls || "[]").reduce((collection, control) => {
        collection[`${control}Control`] = true;
        return collection;
    }, {});
    const infoWindowContent = container.querySelector("[data-info-window]");

    const mapCoords = {
        lat: Number(data.latitude),
        lng: Number(data.longitude)
    };

    const map = new google.maps.Map(element, {
        ...controls,
        center: mapCoords,
        // Turn all UI off:
        // editors will activate the desired ones on a per map basis
        disableDefaultUI: true,
        mapTypeControlOptions,
        styles: setStyles(),
        zoom: Number(data.zoom)
    });

    const marker = createMarker(map, mapCoords, data.pinTitle);

    if (infoWindowContent) {
        createInfoWindow(map, marker, infoWindowContent.innerHTML);
    }

    return map;
}

function initMaps() {
    const maps = getMaps().map((element) => createGoogleMap(element));
    return maps;
}

export default (function googleMaps() {
    if (getMaps().length) {
        // Call the Google Maps API once per page load
        loadScript({ src: `https://maps.googleapis.com/maps/api/js?key=${apiKey}` }, initMaps);
    }
})();
