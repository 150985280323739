/**
 * A global object of custom event names used in the project
 * -- Create names once here; import/reference wherever required
 * -- Namespaced by component, for clarity
 */

const EVENTS = {
    ACCORDION: {
        ACCORDION_OPENED: "ACCORDIONS:ACCORDION:OPENED"
    },

    COOKIES_NOTIFICATION: {
        COOKIES_ACCEPTED: "COOKIES:NOTIFICATION:ACCEPTED"
    },

    COUNTRY_SELECTOR: {
        LOCALE_CHANGED: "COUNTRYSELECTOR:LOCALE:CHANGED"
    },

    MODAL_OVERLAY: {
        MODAL_CLOSED: "MODAL:CLOSED",
        MODAL_OPENED: "MODAL:OPENED"
    },

    MULTISELECTOR: {
        OPTION_CHANGED: "MULTISELECTOR:CHANGED"
    },

    NAVIGATION: {
        NAVIGATION_STATUS: "NAVIGATION:STATUS"
    },

    TABS: {
        TAB_OPENED: "TABS:TAB:OPENED"
    },

    VIEWPORT_WATCHER: {
        TABLET_SWITCH: "VIEWPORT:TABLET:SWITCH",
        DESKTOP_SWITCH: "VIEWPORT:DESKTOP:SWITCH"
    }
};

export default EVENTS;
