/**
 * Hero JavaScript
 */

 import { isElement } from "../../../../../../../clientlibs/v1/publish/js/utils/element";
 import { prefersReducedMotion, scrollToElementTop } from "../../../../../../../clientlibs/v1/publish/js/utils/window";

const targetSelector = `header[data-component='hero']`;
const hero = document.querySelector(targetSelector);

let visitorPausedVideo = false;

/**
 * Utility to check hero type
 * @param {String} type - Current options: "primary", "secondary" or "simple"
 */
// function heroIs(type) {
//     return hero.classList.contains(`hero--${type}`);
// }

function autoPlayVideo(video) {
    video.setAttribute("autoplay", "");
    video.play();
}

function togglePlay(e) {
    const { key, target, type } = e;
    if (type === "click" || key.toUpperCase() === "ENTER") {
        target[target.paused ? "play" : "pause"]();
        visitorPausedVideo = target.paused;
    }
}

function simpleControl(video) {
    video.setAttribute("tabindex", "0");
    video.addEventListener("click", togglePlay, false);
    video.addEventListener("keydown", togglePlay, false);
}

function onScreenObserver(video) {
    const intersectionObserver = new IntersectionObserver((entries) => {
        // Off screen
        if (entries[0].intersectionRatio <= 0) {
            video.pause();
            return;
        }

        if (!visitorPausedVideo) {
            video.play();
        }
    });

    intersectionObserver.observe(video);
}

/**
 * Check if we have a video hero AND visitor's browser settings
 * do not indicate a preference for reduced motion (A11y).
 *
 * @note:
 * If browser doesn't recognize `prefers-reduced-motion`, we assume
 * no movement preferred (as precaution). This means no `autoplay` on:
 *
 * - Edge (non-Chromium versions <= 45)
 * - IE11
 * - Samsung Internet
 *
 * For more information on `prefers-reduced-motion`, visit Mozilla Developer Network
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 */
function initVideo() {
    const video = hero.querySelector("video");

    if (prefersReducedMotion() || !isElement(video)) {
        return;
    }

    autoPlayVideo(video);
    simpleControl(video);
    onScreenObserver(video);
}

function initArrow() {
    const arrow = hero.querySelector("a.hero-arrow");
    const nextElement = hero.nextElementSibling;

    if (!isElement(arrow) || !isElement(nextElement)) {
        return;
    }

    const href = arrow.getAttribute("href") || "";
    arrow.hidden = false;
    nextElement.setAttribute("id", href.replace(/^#/, ""));

    arrow.addEventListener(
        "click",
        (e) => {
            e.preventDefault();
            scrollToElementTop(nextElement);
        },
        false
    );
}

export default (function createHero() {
    if (!isElement(hero)) {
        return;
    }

    initArrow();
    initVideo();
})();
