/**
 * Transform a query string style input of `key=value` pairs (separated by `&`)
 * into an object for easier handling
 *
 * @param {String} query - Query string with or without the leading `?` character
 * @return  {Object}
 */
export function getQueryStringData(query) {
    const params = String(query).replace(/^\?/, "");

    if (params.length <= 0) {
        return {};
    }

    return params.split("&").reduce((collection, param) => {
        const [key, value] = param.split("=");
        collection[key] = value;
        return collection;
    }, {});
}

/**
 * Transform an object into a query string
 *
 * @param {Object} params
 * @return  {String}
 */
export function setQueryString(params) {
    const q = Object.keys(params).map((param) => {
        if (params[param] === null || params[param] === undefined) {
            return `${param}`;
        }
        return `${param}=${params[param]}`;
    });

    return q.length > 0 ? `?${q.join("&")}` : "";
}

function queryString() {
    return {
        get: getQueryStringData,
        set: setQueryString
    };
}

// Expose query string utility on the project global namespace to help
// editors and QA teams test queries
window.SIW.queryString = queryString();

export default queryString();
