/**
 * A list of all the HTML focusable elements
 */
export const focusableElements = (() => {
    return [
        "a[href]",
        "area[href]",
        "audio[controls]",
        "button:not([disabled])",
        "embed",
        "iframe",
        "input:not([disabled]):not([type='hidden'])",
        "object",
        "select:not([disabled])",
        "summary",
        "textarea:not([disabled])",
        "video[controls]",
        "[contenteditable]",
        // @note: `tabindex="-1"` is for JS focus only; not part of regular keyboard focusable elements
        "[tabindex^='0']",
        "[tabindex^='1']",
        "[tabindex^='2']",
        "[tabindex^='3']",
        "[tabindex^='4']",
        "[tabindex^='5']",
        "[tabindex^='6']",
        "[tabindex^='7']",
        "[tabindex^='8']",
        "[tabindex^='9']"
    ].join(",");
})();

/**
 * Convert a DOM element array-like object into a true array
 * Array methods can then be used on them: e.g. forEach
 * More robust than Array.prototype.forEach.call(elements, function (el, i) {...});
 * @param  {Object} arrayLikeObject
 * @param  {Instance} CollectionType
 * @return {Array}
 */
function convertToTrueArray(arrayLikeObject, CollectionType) {
    const elementArray = [];
    if (!(arrayLikeObject instanceof CollectionType)) {
        return elementArray;
    }
    for (let i = 0; i < arrayLikeObject.length; i += 1) {
        elementArray.push(arrayLikeObject[i]);
    }
    return elementArray;
}

/**
 * Convert DOM element HTMLCollection into a true array
 * e.g. ParentNode.children
 * @param   {Object}  elementList
 * @return  {Array}
 */
export function htmlCollectionArray(elementList) {
    return convertToTrueArray(elementList, HTMLCollection);
}

/**
 * Load external JavaScript files
 * e.g. Google Maps or other platform APIs
 * @param   {Object}  attributes
 * @return  {Element}
 */
export function loadScript(attributes, callback = () => {}) {
    const script = document.createElement("script");
    Object.keys(attributes).map((key) => script.setAttribute(key, attributes[key]));
    // As we are loading script dynamically, we'll enforce `async` & `defer` for non-blocking script execution
    script.async = true;
    script.defer = true;
    script.onload = function ready() {
        callback();
    };
    document.body.appendChild(script);
    return script;
}

/**
 * Convert a DOM element NodeList into a true array
 * Array methods can then be used on them: e.g. forEach
 * More robust than Array.prototype.forEach.call(elements, function (el, i) {...});
 * @param  {Object} nodeList
 * @return {Array}
 */
export function nodeListArray(elementList) {
    return convertToTrueArray(elementList, NodeList);
}

export default {
    focusableElements,
    htmlCollectionArray,
    loadScript,
    nodeListArray
};
