/**
 * TOC (Table-of-contents) JavaScript
 */

import { nodeListArray } from "../../../../../../../clientlibs/v1/publish/js/utils/dom";
import { camelCase, truncate } from "../../../../../../../clientlibs/v1/publish/js/utils/string";

const tocName = "toc";
const contentSelector = "main";
const tagStringSelector = "h1, h2, h3, h4, h5, h6";
const targetSelector = `nav[data-component='${tocName}']`;
let idMemo = [];

function countDuplicateIds(idValue) {
    const duplicateNum = idMemo.filter((memorizedId) => memorizedId === idValue).length;
    idMemo.push(idValue);
    return duplicateNum;
}

/**
 * Create a unique camel-cased heading ID from the provided text
 *
 * @param   {Element}  element  -- The heading DOM element
 * @param   {String}   text     -- The source for manufacturing ID from
 *
 * @return  {String}
 */
export function setHeadingId(element) {
    // If element already has an id, use it.
    // ...Or, camel-case & limit to length of heading `id` to 30 characters
    // (camelCase additionally removes special characters)
    const id = element.hasAttribute("id") ? element.getAttribute("id") : truncate(camelCase(element.textContent), 30);

    if (!id.length) {
        return id;
    }

    const incrementId = countDuplicateIds(id);
    const refreshedId = incrementId > 0 ? `${id}-${incrementId}` : id;
    element.setAttribute("id", refreshedId);
    element.setAttribute("tabindex", "-1"); // SWD-85 (IE11 tab-stop bug)
    return refreshedId;
}

/**
 * Filter-out headings that:
 * -- Have the `data-toc-ignore` attribute, or a parent element that does
 * -- Don't contain any content (editor error)
 *
 * @return  {Array} -- All the h-tags post TOC position in the document
 */
export function filterHeadings() {
    idMemo = [];
    return nodeListArray(document.querySelectorAll(`${contentSelector} ${tagStringSelector}`))
        .filter((hElement) => {
            return hElement.hasAttribute("data-toc-ignore") || hElement.closest("[data-toc-ignore]") ? false : hElement;
        })
        .filter((hElement) => {
            return setHeadingId(hElement).length;
        });
}

/**
 * Run through the collected page headings (in the content area of a page).
 * Output an ordered list of targeted links.
 *
 * @return  {String}  -- String of HTML output
 */
function setList() {
    const generateListItems = filterHeadings().map(
        (heading) => `<li><a href="#${heading.getAttribute("id")}">${heading.textContent.trim()}</a></li>`
    );

    if (!generateListItems.length) {
        return "";
    }
    return [].concat(`<ol>`, generateListItems, `</ol>`).join("");
}

/**
 * Initializing function call
 *
 * @return  {Element}
 */
export function create() {
    const target = document.querySelector(targetSelector);
    if (!target) {
        return;
    }

    const toc = setList();
    if (toc) {
        target.innerHTML = toc;
        target.removeAttribute("hidden");
    }
}

export default (function createTableOfContents() {
    create();
})();
