import { nodeListArray } from "../utils/dom";
import { isOverlay } from "../utils/window";

// @todo:
// - Put in something to remove elements as early as possible so they are
//   not shown to visitor before being removed

export default function removePageElements() {
    const selectors = ["a.skip-link", "header.site-header", "footer.footer", ".modal-mask"];
    const removables = nodeListArray(document.querySelectorAll(selectors.join(",")));

    if (!isOverlay()) {
        return;
    }

    removables.forEach((el) => {
        el.parentNode.removeChild(el);
    });
}
