import cookiehelper from "../../../../../../../clientlibs/v1/publish/js/utils/cookies";
import cookie from "../../../../../../content/cookie/clientlibs/v1/publish/js/cookie";
import { getPageTemplate } from "../../../../../../../clientlibs/v1/publish/js/utils/aem";

export default (function marketPicker() {
    const component = [];

    component["name"] = "market-picker";
    if (document.querySelector("." + component.name)) {
        const countryBtn = document.querySelectorAll(".cmp-market-picker__country");
        const revealInvestorsBtn = document.querySelectorAll(".reveal-investors");
        const openerBtn = document.querySelector(`.cmp-${component.name}__opener`);
        const closerBtn = document.querySelector(`.cmp-${component.name}__closer`);
        const backBtn = document.querySelector("#important-information--back");
        const loadInvestorRoot = document.querySelectorAll(".investors__load-investor-root");
        const acceptBtn = document.querySelector("#important-information--accept");
        const declineBtn = document.querySelector("#important-information--decline");
        const showLanguages = document.querySelector(`.cmp-${component.name}__language-opener`);
        const languages = document.querySelector(`.cmp-${component.name}__languages`);
        const authorqs = document.querySelector("html[data-mode='author']") || new URLSearchParams(window.location.search).get("wcmmode") != null ? "?wcmmode=disabled" : "";

        const cookieNameCountry = "country";
        const cookieNameLanguage = "language";
        const cookieNameInvestorType = "investor_type";
        const cookieNameSite = "site";
        if (document.querySelector("[data-site-marketIndicator]").dataset.siteMarketindicator.includes('EN-US/home')) {
            document.querySelector(".cmp-market-picker__opener").style.display = "none";
            document.querySelector(".cmp-market-picker").style.height = "0em";
        }

        revealInvestorsBtn.forEach((item) => {
            item.addEventListener("click", (e) => {
                document.querySelectorAll(".investors").forEach((el) => {
                    el.classList.add("hidden");
                });
                let selector = "#market_investors_" + e.target.dataset.market_investors;
                document.querySelector(selector).classList.remove("hidden");

                cookiehelper.set("email-link", false);
            });
        });

        openerBtn.addEventListener("click", (e) => {
            document.querySelector(`.cmp-${component.name}`).classList.add("opened");

            // cookiehelper.set('email-link', false);
        });

        closerBtn.addEventListener("click", (e) => {
            document.querySelector(`.cmp-${component.name}`).classList.remove("opened");
        });

        backBtn.addEventListener("click", (e) => {
            document.querySelector(`.cmp-${component.name}__important-information`).classList.remove("loaded");
            document.querySelector("#important-information").innerHTML = "";
        });

        loadInvestorRoot.forEach((item) => {
            item.addEventListener("click", (e) => {
                e.preventDefault();
                let $this = e.target;
                let url = $this.dataset.xf_path;
                let href = $this.dataset.href + authorqs;
                let investorType = e.currentTarget.dataset.investor_type;
                let country = e.currentTarget.dataset.country;
                let language = e.currentTarget.dataset.language;
                let site = e.currentTarget.dataset.site;
                if (
                    typeof url != "undefined" &&
                    typeof href != "undefined" &&
                    typeof cookiehelper.get(`${country}_${language}_${investorType}_terms_agreed`) === 'undefined' ||
                    cookiehelper.get(`${country}_${language}_${investorType}_terms_agreed`) !== "true"
                ) {
                    // hide close of market picker until the aagree
                    document.querySelector(".cmp-market-picker__closer").style.display = "none";
                    fetch(url + authorqs)
                        .then((response) => {
                            return response.text();
                        })
                        .then((html) => {
                            var parser = new DOMParser();
                            var parsedTermsHtml = parser.parseFromString(html, "text/html");
                            let termTextBlock = parsedTermsHtml.querySelector(
                                'div.container .text[data-component="text"]'
                            );

                            document
                                .querySelector(`.cmp-${component.name}__important-information`)
                                .classList.add("loaded");
                            document.querySelector("#important-information").appendChild(termTextBlock);
                        })
                        .catch((err) => {
                            console.warn("Error retreiving terms XF!", err);
                        });
                    if (cookiehelper.get("email-link") == "true") {
                        let qs = window.location.search.indexOf("wcmmode=disabled") > -1 ? window.location.search.replace('?wcmmode=disabled', "").replace('&wcmmode=disabled', "") : window.location.search;
                        let fixedAuthorQs = (qs.length > 1 ? '&' : '?') + authorqs.replace('?', '');
                        let finalQs = qs + fixedAuthorQs;
                        href = window.location.pathname + finalQs.length > 1 ? finalQs : '';
                    }
                    acceptBtn.setAttribute("href", href);
                    acceptBtn.setAttribute("data-country", e.currentTarget.getAttribute("data-country"));
                    acceptBtn.setAttribute("data-language", e.currentTarget.getAttribute("data-language"));
                    acceptBtn.setAttribute("data-investor_type", e.currentTarget.getAttribute("data-investor_type"));
                    declineBtn.setAttribute("href", "/");
                } else {
                    const current_site_country = document
                        .querySelector("[data-site-country]")
                        .dataset.siteCountry.toLowerCase();

                    cookiehelper.set(cookieNameCountry, country)
                    cookiehelper.set(cookieNameInvestorType, investorType);
                    cookiehelper.set(cookieNameLanguage, language);
                    cookiehelper.set(cookieNameSite, site);

                    if ($this.getAttribute("href") == "" || $this.getAttribute("href") == null) {
                        let investorType = e.currentTarget.dataset.investor_type;
                        let country = e.currentTarget.dataset.country;
                        let language = e.currentTarget.dataset.language;

                        if (cookiehelper.get("email-link") == "true") {
                            let qs = window.location.search.indexOf("wcmmode=disabled") > -1 ? window.location.search.replace('?wcmmode=disabled', "").replace('&wcmmode=disabled', "") : window.location.search;
                            let fixedAuthorQs = (qs.length > 1 ? '&' : '?') + authorqs.replace('?', '')
                            let finalQs = qs + fixedAuthorQs;
                            href = window.location.pathname + finalQs.length > 1 ? finalQs : '';
                        }
                        window.location = href;
                    } else {
                        window.location = $this.getAttribute("href") + authorqs;
                    }
                }
            });
        });

        if (showLanguages) {
            showLanguages.addEventListener("click", (e) => {
                languages.classList.toggle("slideInDown");
                languages.classList.toggle("slideOutUp");
                e.currentTarget.classList.toggle(`cmp-${component.name}__language-opener--closed`);
                languages.setAttribute(
                    "aria-expanded",
                    e.currentTarget.classList.contains(`cmp-${component.name}__language-opener--closed`)
                        ? "false"
                        : "true"
                );
            });
        }

        acceptBtn.addEventListener("click", (e) => {
            const cookied_country = cookiehelper.get(cookieNameCountry)
                ? cookiehelper.get(cookieNameCountry).toLowerCase()
                : null;
            const cookied_language = cookiehelper.get(cookieNameLanguage)
                ? cookiehelper.get(cookieNameLanguage).toLowerCase()
                : null;
            const cookied_investor_type = cookiehelper.get(cookieNameInvestorType)
                ? cookiehelper.get(cookieNameInvestorType).toLowerCase()
                : null;

            cookiehelper.expire(`${cookied_country}_${cookied_language}_${cookied_investor_type}_terms_agreed`);

            let investorType = e.currentTarget.dataset.investor_type;
            let country = e.currentTarget.dataset.country;
            let language = e.currentTarget.dataset.language;
            const current_site_country = document
                .querySelector("[data-site-country]")
                .dataset.siteCountry.toLowerCase();

            cookiehelper.set(cookieNameCountry, country)
            cookiehelper.set(cookieNameInvestorType, investorType);
            cookiehelper.set(cookieNameLanguage, language);

            cookiehelper.set(`${country}_${language}_${investorType}_terms_agreed`, "true");
            // if (current_site_country == "global") {
            //     cookiehelper.set("global-site-preferred", true);
            // }
        });

        countryBtn.forEach((item) => {
            if (window.location.href.indexOf("/" + item.getAttribute("data-country") + "/") > -1) {
                item.classList.add("active-country");
            }
            item.addEventListener("click", (e) => {
                if (document.querySelector(".active-country")) {
                    document.querySelector(".active-country").classList.remove("active-country");
                }
                e.currentTarget.classList.toggle("active-country");
            });
        });

        document.querySelectorAll(".cmp-market-picker__country").forEach((mainitem) => {
            mainitem.addEventListener("mouseover", (e) => {
                document.querySelectorAll(".cmp-market-picker__country").forEach((item) => {
                    item.classList.add("forced-inactive");
                });
                e.currentTarget.classList.add("temp-active");
            });
            mainitem.addEventListener("mouseout", (e) => {
                document.querySelectorAll(".cmp-market-picker__country").forEach((item) => {
                    item.classList.remove("forced-inactive");
                });
                e.currentTarget.classList.remove("temp-active");
            });
        });

        /** mobile jazz */
        document.querySelector(".mobile-wrapper").addEventListener("click", (e) => {
            if (!e.target.classList.contains("cmp-market-picker__language-opener")) {
                e.currentTarget.classList.toggle("mobile-click");
                document
                    .querySelector(".cmp-market-picker__wrapper__investors-container")
                    .classList.remove("country-selected");
                if (e.currentTarget.classList.contains("mobile-click")) {
                    document.querySelector(".mobile-wrapper").setAttribute("data-before", "Select country");
                } else {
                    document.querySelector(".mobile-wrapper").setAttribute("data-before", "Select investor type");
                }
            }
        });
        document.querySelectorAll(".mobile-wrapper .cmp-market-picker__country.reveal-investors").forEach((item) => {
            item.addEventListener("click", (e) => {
                document.querySelector(".mobile-click")
                    ? document.querySelector(".mobile-click").classList.remove(".mobile-click")
                    : null;
                document
                    .querySelector(".cmp-market-picker__wrapper__investors-container")
                    .classList.add("country-selected");
                document.querySelector(".mobile-wrapper").setAttribute("data-before", "Select investor type");
            });
        });
        document
            .querySelector(".mobile-wrapper")
            .parentElement.parentElement.querySelector(".cmp-market-picker__closer")
            .addEventListener("click", (e) => {
                const investorsElement = document.querySelector(".investors:not(.hidden)");
                if(investorsElement !== undefined && investorsElement !== null){
                    investorsElement.classList.add("hidden");
                }
                document
                    .querySelector(".cmp-market-picker__wrapper__investors-container")
                    .classList.remove("country-selected");
                // document.querySelector('.mobile-wrapper').setAttribute('data-before', 'Select country');
            });

        window.addEventListener("load", () => {

            // if (document.querySelector('.author')) {
            //     let authorqs = "?wcmmode=disabled"
            // }
            if (document.referrer == "") {
                cookiehelper.set("email-link", true);
            } else {
                cookiehelper.set("email-link", false);
            }
            const cookied_country = cookiehelper.get(cookieNameCountry)
                ? cookiehelper.get(cookieNameCountry).toLowerCase()
                : null;
            const cookied_language = cookiehelper.get(cookieNameLanguage)
                ? cookiehelper.get(cookieNameLanguage).toLowerCase()
                : null;
            const cookied_investor_type = cookiehelper.get(cookieNameInvestorType)
                ? cookiehelper.get(cookieNameInvestorType).toLowerCase()
                : null;

            const current_site_country = document
                .querySelector("[data-site-country]")
                .dataset.siteCountry.toLowerCase();
            const current_site_language = document
                .querySelector("[data-site-language]")
                .dataset.siteLanguage.toLowerCase();

            const dataSiteInvestorTypeElement = document.querySelector("[data-site-investor-type]");
            const current_site_investor_type = dataSiteInvestorTypeElement!== undefined && dataSiteInvestorTypeElement!== null ? dataSiteInvestorTypeElement.dataset.siteInvestorType.toLowerCase().replace(' ', '-') : "";

            if (
                document
                    .querySelector("html")
                    .classList.toString()
                    .indexOf("aem-AuthorLayer-") == -1 &&
                !document.querySelector("html").hasAttribute("data-mode") &&
                current_site_country != "language-masters"
            ) {
                if (
                    (current_site_country == "global" &&
                        // cookiehelper.get("global-site-preferred") != "true" &&
                        cookiehelper.get(`null_en_all_terms_agreed`) != "true") ||
                    current_site_country != "global"
                ) {
                    if (
                        !cookied_country ||
                        !cookied_language ||
                        !cookied_investor_type ||
                        cookied_country != current_site_country ||
                        cookied_language != current_site_language ||
                        cookied_investor_type != current_site_investor_type) {
                        // add this OR to check and open the market picker if there is no agree cookie set
                        // || typeof cookiehelper.get(`${cookied_country}_${cookied_language}_${cookied_investor_type}_terms_agreed`) === 'undefined'

                        // test if an agreed terms cookie exists
                        let termsAgreedCookieSet = false;
                        document.cookie.split(" ").forEach(cookie => { if (cookie.indexOf("_terms_agreed") > -1) termsAgreedCookieSet = true });
                        // open market picker as there are no cookies set
                        if (
                            !termsAgreedCookieSet ||
                            (termsAgreedCookieSet && cookiehelper.get(`${current_site_country}_${current_site_language}_${current_site_investor_type}_terms_agreed`) !== "true" && current_site_country != "global")
                        ) {
                            if (window.SIW.isCampaignPage !== "true") {
                                document.querySelector(".cmp-market-picker__opener").click();

                                if (current_site_country != "global" || (current_site_country == "global" && window.location.pathname != "/")) {
                                    document
                                        .querySelector(
                                            '[data-market_investors="' + current_site_country + "_" + current_site_language + '"]'
                                        )
                                        .click();

                                    cookiehelper.set("email-link", true);

                                    if(document.querySelector(
                                            '[data-investor_type="' +
                                            current_site_investor_type +
                                            '"][data-country="' +
                                            current_site_country +
                                            '"][data-language="' +
                                            current_site_language +
                                            '"]'
                                        ) != null){
                                    document.querySelector(
                                            '[data-investor_type="' +
                                            current_site_investor_type +
                                            '"][data-country="' +
                                            current_site_country +
                                            '"][data-language="' +
                                            current_site_language +
                                            '"]'
                                        )
                                        .click();
                                    }
                                }
                                // On Global site, launch the region chooser
                                if (current_site_country == "global" && window.location.pathname == "/") {
                                    // callIpLookUpTool()

                                    fetch("https://extreme-ip-lookup.com/json/?key=qgv9ro1OkiAdWETg3W19").then(res => res.json().then(function (data) {
                                        console.log("response.countryCode" + data.countryCode);
                                        var countryCode = data.countryCode;
                                        var languageCode = "en";
                                        var currentLocale = window.SIW.region;
                                        // if the country code returned in "GB" then replace it with "UK"

                                        if (countryCode == 'GB') {
                                            countryCode = 'uk';
                                        }

                                        window.SIW.region = {
                                            current_site_country: countryCode.toLowerCase(),
                                            current_site_language: languageCode
                                        };
                                        document.querySelector('[data-market_investors="' + countryCode.toLowerCase() + "_" + languageCode + '"]').click();
                                    }).catch(function (error) {
                                        console.warn("Fetch operation error: ".concat(error.message));
                                    })
                                    )
                                }
                            }
                        }
                    }
                }
            }
        });
    }

    /*async function callIpLookUpTool() {
        var response = await fetch("https://extreme-ip-lookup.com/json/?key=qgv9ro1OkiAdWETg3W19");

        if (!response.ok) {
            throw new Error("HTTP error status: ".concat(response.status));
        }
        console.log(response.json());
        return await response.json();
    }*/
})();
