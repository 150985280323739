export function setTextByAttribute(element, attr) {
    const attributeSelector = `data-text-${attr}`;
    const attribute = element && element.getAttribute(attributeSelector);
    if (!attribute) {
        return;
    }
    element.textContent = attribute;
}

function menuAction(open, button, buttonTextAttribute) {
    if (!(button instanceof Element)) {
        return;
    }

    const drawer = document.getElementById(button.getAttribute("aria-controls")) || button.nextElementSibling;
    setTextByAttribute(button.querySelector(".button-text"), buttonTextAttribute);
    button.setAttribute("aria-expanded", open);
    drawer.hidden = !open;
}

export function openMenuAction(...args) {
    menuAction(true, ...args);
}

export function closeMenuAction(...args) {
    menuAction(false, ...args);
}

export default {
    closeMenuAction,
    openMenuAction,
    setTextByAttribute
};
