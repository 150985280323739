import { isInternalLink } from "../utils/aem";
import queryString from "../utils/querystring";
import { isOverlay } from "../utils/window";

// @todo:
// - URL hash scrollTo not happening in overlay

function updateLink(element) {
    const { hash, pathname, search } = element;
    const locationParams = queryString.get(window.top.location.search);
    const targetParams = queryString.get(search);
    const params = {
        ...locationParams,
        ...targetParams,
        overlay: null // Append overlay marker
    };

    return `${pathname}${queryString.set(params)}${hash}`;
}

export default function overlayLinks() {
    document.addEventListener(
        "click",
        (e) => {
            const { target } = e;

            // Quick exit
            if (!isOverlay()) {
                return;
            }

            if (target.hasAttribute("href")) {
                // External link needs to target the top-most window to
                // escape the overlay iframe
                if (!isInternalLink(target)) {
                    target.setAttribute("target", "_blank");
                    return;
                }

                // Otherwise, preserve top window query strings, e.g. `wcmmode`
                // and add an overlay identifier for the site pages to watch for
                e.preventDefault();
                window.location.href = updateLink(target);
            }
        },
        false
    );
}
