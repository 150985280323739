import modal from "../../../../../../content/modal/clientlibs/v1/publish/js/modal";
import cookies, { getTermsCookieName } from "../../../../../../../clientlibs/v1/publish/js/utils/cookies";
import event from "../../../../../../../clientlibs/v1/publish/js/utils/publisherSubscriber";
import { isOverlay } from "../../../../../../../clientlibs/v1/publish/js/utils/window";
import { isElement } from "../../../../../../../clientlibs/v1/publish/js/utils/element";

const globalSite = "https://www.stewartinvestors.com/";

const win = window.top;
const { SIW } = win;
const termsCookieName = getTermsCookieName();

var triggerSelector = "[data-region-trigger]";
var trigger = document.querySelector(triggerSelector);

function getHomePage() {
    const { pathname, search } = window.location;
    const pattern = new RegExp(
        [".*", SIW.region, SIW.language, SIW.investorType].map((part) => part.toLowerCase()).join("/")
    );
    const match = pathname.match(pattern);
    return match && match.length > 0 ? `${match[0]}.html${search}` : null;
}

function eventSubscribe() {
    event.subscribe("/important/terms", (data) => {
        const { accepted } = data;
        const home = getHomePage();

        win.dispatchEvent(new CustomEvent("modal:close"));

        if (!accepted) {
            win.location = globalSite;
            return;
        }

        if (!isOverlay() && home && accepted) {
            win.location = home;
        }
    });
}

function getSIWCookie() {
	const docCookie = document.cookie;
		const terms= "SIW_TERMS";
	    if(docCookie.indexOf(terms) !== -1){
	    	return true;
	    	}
	    return false;
	}

export default (function createImportantFormModal() {
    if (SIW && SIW.region.toUpperCase() !== "GLOBAL" && !cookies.get(termsCookieName)) {
        const importantInfo = modal(SIW.terms.url, {
            lock: true,
            ariaLabel: SIW.terms.label
        });

        win.addEventListener(
            "modal:close",
            () => {
                importantInfo.close();
            },
            false
        );

        //importantInfo.open();
        eventSubscribe();
    }
    if (SIW && SIW.region.toUpperCase() === "GLOBAL" && isElement(trigger) && !getSIWCookie() && !cookies.get(termsCookieName) && !cookies.get('SIW_REGION_SELECTED')) {
        const regionChooser = modal(trigger, {
            lock: false,
            ariaLabel: trigger.getAttribute("data-modal-aria-label")
        });

        win.addEventListener(
                "modal:close",
                () => {
                	regionChooser.close();
                },
                false
            );
        //regionChooser.open();
        // Make public on the project namespace

        window.SIW.regionChooser = regionChooser;
    }
})();