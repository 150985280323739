import cookiehelper from "../../../../../../../clientlibs/v1/publish/js/utils/cookies";

export default (function cookie() {

    const component = [];
    component["name"] = "cookie";
    const cookieWrapper = document.querySelector(`.cmp-${component.name}`);
    if (cookieWrapper) {
        const acceptAllBtn = document.querySelectorAll(`.cmp-${component.name}__btns--accept-all`);
        const rejectAllBtn = document.querySelector(`.cmp-${component.name}__btns--reject-all`);
        const confirmSelectionBtn = document.querySelector(`.cmp-${component.name}__btns--confirm`);
        const editCookieSettings = document.querySelector(`.cmp-${component.name}__btns--edit`);
        const changeCookieSettings = document.querySelector(`.cmp-${component.name}__btns--change-settings`);

        const acceptPerformance = document.querySelector(
            `.cmp-${component.name}__panel-edit-perf input[name='performance']`
        );
        const acceptFunctional = document.querySelector(
            `.cmp-${component.name}__panel-edit-func input[name='functional']`
        );
        const editCookiesPanel = document.querySelector(`.cmp-${component.name}__panel-edit`);

        const neccessaryCookieName = "gprs_neccessary";
        const functionalCookieName = "gprs_functional";
        const performanceCookieName = "gprs_performance";
        const fakeradio = document.querySelectorAll(`.cmp-${component.name}__radio`);
        const fakeradiotrigger = document.querySelectorAll(".radiotrigger");

        acceptAllBtn.forEach((item) => {
            item.addEventListener("click", (e) => {
                wipeGprsCookies();
                cookiehelper.set(neccessaryCookieName, true);
                cookiehelper.set(functionalCookieName, true);
                cookiehelper.set(performanceCookieName, true);
                window.location.reload();
            });
        });

        rejectAllBtn.addEventListener("click", (e) => {
            wipeGprsCookies();
            cookiehelper.set(neccessaryCookieName, true);
            cookiehelper.set(functionalCookieName, false);
            cookiehelper.set(performanceCookieName, false);
            window.location.reload();
        });

        confirmSelectionBtn.addEventListener("click", (e) => {
            wipeGprsCookies();
            cookiehelper.set(neccessaryCookieName, true);
            cookiehelper.set(functionalCookieName, acceptFunctional.checked ? true : false);
            cookiehelper.set(performanceCookieName, acceptPerformance.checked ? true : false);
            window.location.reload();
        });

        editCookieSettings.addEventListener("click", (e) => {
            document.querySelector(`.cmp-${component.name}__panel-intro`).classList.toggle(`cmp-${component.name}__panel-intro--closed`);
            setTimeout(() => editCookiesPanel.classList.toggle(`cmp-${component.name}__panel-edit--open`), 500);
            window.scrollTo({top: 0})
        });

        function wipeGprsCookies() {
            cookiehelper.set(neccessaryCookieName, "");
            cookiehelper.set(functionalCookieName, "");
            cookiehelper.set(performanceCookieName, "");
        }
        changeCookieSettings.addEventListener("click", (e) => {
            cookieWrapper.classList.add("cookies-not-accepted");
            document.querySelector(`.cmp-${component.name}__btns--edit`).focus();
        });
        fakeradiotrigger.forEach((item) => {
            if (!item.classList.contains('disabled')) {
                item.addEventListener("click", (e) => {
                    e.target.parentElement.classList.toggle("on");
                    e.target.parentElement.classList.contains("on")
                        ? (e.target.parentElement.parentElement.querySelector("input").checked = true)
                        : (e.target.parentElement.parentElement.querySelector("input").checked = false);
                });
            }
        });
        fakeradio.forEach((item) => {
            item.addEventListener("keypress", (e) => {
                if (e.key === "Enter" || e.key === " ") {
                    e.target.classList.toggle("on");
                    e.target.classList.contains("on")
                        ? (e.target.parentElement.querySelector("input").checked = true)
                        : (e.target.parentElement.querySelector("input").checked = false);
                }
            });
        });

        if (cookiehelper.get(performanceCookieName) == "true") {
            document.querySelector(`.cmp-${component.name}__panel-edit-perf .radiotrigger`).click();
        }

        if (cookiehelper.get(functionalCookieName) == "true") {
            document.querySelector(`.cmp-${component.name}__panel-edit-func .radiotrigger`).click();
        }
        window.addEventListener('load', () => {
            if (
                typeof cookiehelper.get(neccessaryCookieName) === undefined ||
                cookiehelper.get(neccessaryCookieName) === "null" ||
                !cookiehelper.get(neccessaryCookieName) ||
                typeof cookiehelper.get(functionalCookieName) === undefined ||
                cookiehelper.get(functionalCookieName) === "null" ||
                !cookiehelper.get(functionalCookieName) ||
                typeof cookiehelper.get(performanceCookieName) === undefined ||
                cookiehelper.get(performanceCookieName) === "null" ||
                !cookiehelper.get(performanceCookieName)) {
                cookieWrapper.classList.add("cookies-not-accepted");
                document.querySelector(`.cmp-${component.name}__btns--edit`).focus();
            } else {
                if (document.querySelector('.footer-links-col2') !== null) {
                    document.querySelector('.footer-links-col2').append(changeCookieSettings);
                    changeCookieSettings.removeAttribute('hidden');
                }
            }
        })
    }
})();
