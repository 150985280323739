export default function debounce(fn, wait = 250, immediate) {
    let timeout;
    return function db(...args) {
        const context = this;
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            if (!immediate) {
                fn.apply(context, args);
            }
        }, wait);
        if (callNow) {
            fn.apply(context, args);
        }
    };
}
