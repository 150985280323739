export default (function sharePrice() {
    if (document.querySelector("section.cmp-share-price-mutual")) {
        document.querySelectorAll("section.cmp-share-price-mutual").forEach( (elm) => {
            const wideTable = elm.querySelector("table.wide");
            const narrowTable = elm.querySelector("table.narrow");
            // width at which the table transitions from wide to narrow
            let allowedWidth = 700;
            const whichTable = (elWidth, okWidth) => {
                if (elWidth < okWidth) {
                    if (wideTable) {
                        wideTable.classList.add("hide");
                        wideTable.classList.remove("show");
                    }
                    if (narrowTable) {
                        narrowTable.classList.add("show");
                        narrowTable.classList.remove("hide");
                    }
                } else {
                    if (wideTable) {
                        wideTable.classList.add("show");
                        wideTable.classList.remove("hide");
                    }
                    if (narrowTable) {
                        narrowTable.classList.add("hide");
                        narrowTable.classList.remove("show");
                    }
                }
            }

            window.addEventListener("load", sp);
            window.addEventListener("resize", sp);

            function sp() {
                if (wideTable || narrowTable) {
                    whichTable(elm.clientWidth, allowedWidth);
                }
            }
        })
    }
})();