import { nodeListArray } from "../utils/dom";

/**
 * Make external links safe
 * Prevent links to other pages (opened via `target="_blank"`) from hijacking our pages
 * through the `window.opener` loophole:
 *
 * -- https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#target
 * -- https://mathiasbynens.github.io/rel-noopener/
 *
 * @return  {Array}
 */
export default function makeSafeExternalLinks() {
    const targetBlankLinks = nodeListArray(document.querySelectorAll("a[target='_blank']"));
    return targetBlankLinks.map((link) => {
        return link.setAttribute("rel", "noopener noreferrer");
    });
}
