
/**
 * DOM ready listener
 * e.g. The equivalent of jQuery.ready()
 *
 * @param   {Function}  fn
 */
export function ready(fn) {
    if (document.readyState !== "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export default {
    ready
};
