import { isElement } from "../utils/element";
import { scrollToElementTop } from "../utils/window";

// Find out if anchor is contained in a close accordion panel
// & open it (if this is the case)
function accordionPanelOpen(anchor) {
    const accordionPanel = anchor.closest("[data-accordion-panel]");
    if (accordionPanel) {
        const button = accordionPanel.querySelector("button[aria-expanded='false']");
        if (button) {
            button.click();
        }
    }
}

function makeAdjustment(anchor) {
    const siteHeader = document.querySelector(".site-header");
    const buffer = 30;

    if (!isElement(siteHeader)) {
        return;
    }

    scrollToElementTop(anchor, buffer);
}

function handleAnchor(hashValue) {
    const anchor = document.querySelector(hashValue);
    if (isElement(anchor)) {
        accordionPanelOpen(anchor);
        makeAdjustment(anchor);
        anchor.focus();
    }
}

export default handleAnchor;
