function compareNumbers(a, b) {
    return a - b;
}

function compareStrings(a, b) {
    const itemA = String(a).toUpperCase();
    const itemB = String(b).toUpperCase();
    if (itemA < itemB) {
        return -1;
    }
    if (itemA > itemB) {
        return 1;
    }
    //   Items are the same
    return 0;
}

/**
 * Return sorted array of number values
 * @param   {Array}  array  -- Array of numbers or objects
 * @param   {String}  propertyName -- An optional propertyName for accessing an object value
 * @return  {Array}              -- The sorted array
 */
export function sortByNumbers(array, propertyName) {
    return array.slice().sort((a, b) => {
        const hasOwnProp = Object.prototype.hasOwnProperty;
        if (hasOwnProp.call(a, propertyName) && hasOwnProp.call(b, propertyName)) {
            return compareNumbers(a[propertyName], b[propertyName]);
        }
        return compareNumbers(a, b);
    });
}

/**
 * Return sorted array of string values
 * @param   {Array}  array  -- Array of strings or objects
 * @param   {String}  propertyName -- An optional propertyName for accessing an object value
 * @return  {Array}              -- The sorted array
 */
export function sortByString(array, propertyName) {
    return array.slice().sort((a, b) => {
        const hasOwnProp = Object.prototype.hasOwnProperty;
        if (hasOwnProp.call(a, propertyName) && hasOwnProp.call(b, propertyName)) {
            return compareStrings(a[propertyName], b[propertyName]);
        }
        return compareStrings(a, b);
    });
}

export default {
    sortByNumbers,
    sortByString
};
