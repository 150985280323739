/**
 * Global/Base JavaScript
 */

import debounce from "./utils/debounce";
import { ready } from "./utils/document";
import viewportWatcher from "./utils/viewportWatcher";
import { overlayPresentation, prefersReducedMotion } from "./utils/window";

import handleAnchor from "./base/anchor";
import makeSafeExternalLinks from "./base/externalLinks";
import overlayLinks from "./base/overlayLinks";
import removePageElements from "./base/overlayPage";
import oneTrustConsentBannerSI from "./base/onetrustconsentbanner.js";

// Components
// import "../../components/structure/header/cookie-notification/scripts/cookie-notification";
// import "../../components/structure/header/navigation/scripts/navigtation";
// import "../../components/structure/header/region-choice/scripts/region-choice";

// import "../../components/structure/hero/scripts/hero";
// import "../../../../components/content/table/clientlibs/js/table";
// Fix for SWD-100: call table before accordion to solve `accordion-fundlist` race condition
// import "../../components/content/accordion/scripts/accordion";
// import "../../components/content/google-maps/scripts/google-maps";
// import "../../components/content/text/scripts/text";
// import "../../components/content/toc/scripts/toc";
// import "../../components/content/featured-carousal/scripts/featured-carousal";

// import "../../components/form/article-results/scripts/article-results";
// import "../../components/form/important-info-form/scripts/important-info-form";
// import "../../components/form/important-info-form/scripts/important-info-modal";
// import "../../components/form/region-form/scripts/region-form";

/**
 * Global functionality
 * ====================
 */

// Call things:
//googleAnalytics();
makeSafeExternalLinks();
overlayPresentation();
overlayLinks();
removePageElements();
viewportWatcher.setMediaQueries();
oneTrustConsentBannerSI();

ready(() => {
    const { hash } = window.location;

    if (hash && hash.length > 1) {
        handleAnchor(hash);
    }

    // @ie11: Force picturefill to fire intermittently on scroll to ensure
    // images further down the page get best chance of loading
    window.addEventListener(
        "scroll",
        debounce(() => {
            window.picturefill();
        }, 500),
        false
    );
});

// Expose `prefersReducedMotion` result, so Devs & QA can quickly check the setting
window.SIW.prefersReducedMotion = prefersReducedMotion();