export function getCookieData() {
    const cookies = document.cookie.split(";");
    return cookies.reduce((collection, ck) => {
        const [key, value] = ck.split("=");
        collection[key.trim()] = value;
        return collection;
    }, {});
}

export function getCookie(name) {
    const data = getCookieData();
    if (Object.prototype.hasOwnProperty.call(data, String(name))) {
        return decodeURIComponent(data[String(name)]);
    }
    return undefined;
}

export function setCookie(name, value, attributes = {}) {
    const attrs = { ...attributes, path: "/", SameSite: "lax","max-age": attributes["max-age"] == 0 ? attributes["max-age"] : 5184000 };
    // Flatten attributes into a semi-colon delimited string
    const additionalSettings = Object.keys(attrs)
        .map((attr) => {
            return `${attr}=${attrs[attr]}`;
        })
        .join(";");

    document.cookie = `${name.trim()}=${encodeURIComponent(value)};${additionalSettings}`;
}

export function expireCookie(name) {
    return setCookie(name, null, {
        "max-age": 0
    });
}

export function getTermsCookieName() {
    const { language, region } = SIW;
    return `SIW_TERMS_${language}-${region}`;
}

export function getRegionCookieName() {
    return `SIW_REGION`;
}

function deleteCookie( name, path, domain ) {
    if( getCookie( name ) ) {
      document.cookie = name + "=" +
        ((path) ? ";path="+path:"")+
        ((domain)?";domain="+domain:"") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

function cookie() {
    return {
        data: getCookieData,
        get: getCookie,
        expire: expireCookie,
        set: setCookie,
        delete: deleteCookie
    };
}

// Expose cookie utility on the project global namespace to help
// editors and QA teams test cookies
window.SIW.cookie = cookie();

export default cookie();