/**
 * Return a random number between two values, inclusive
 *
 * @param {Number} min
 * @param {Number} max
 *
 * @return {Number}
 */
export function getRandomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.ceil(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export default {
    getRandomNumber
};
