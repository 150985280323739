import { nodeListArray } from "../../../../../../../clientlibs/v1/publish/js/utils/dom";
import { isElement } from "../../../../../../../clientlibs/v1/publish/js/utils/element";

const form = document.querySelector("[data-component='search-form']");
const results = document.querySelector("[data-component='article-results']");

let elements = {};
let itemCount = 0;
let buttonApi;

function setElements() {
    return {
        filters: nodeListArray(form.querySelectorAll("select")),
        teasers: nodeListArray(results.querySelectorAll("article.teaser-card")),
        noResultsMessage: results.querySelector(".no-results"),
        moreButton: results.querySelector(".more-results")
    };
}

function getFilterValues() {
    const { filters } = elements;
    const values = [];

    filters.forEach((filter) => {
        if (filter.value) {
            values.push(filter.value);
        }
    });

    return values;
}

function filterTeasersByTags(tags) {
    const { teasers } = elements;
    const targetLength = tags.length;
    const matched = teasers.filter((teaser) => {
        const teaserTags = teaser.getAttribute("data-tags");
        const matchedTags = tags.filter((tag) => teaserTags && teaserTags.includes(tag));
        const targetMet = matchedTags.length === targetLength;
        teaser.closest("li").hidden = !targetMet;
        return targetMet;
    });

    return matched;
}

function filterByCount(matchedResults) {
    const { moreButton } = elements;
    const showCount = buttonApi.get();

    if (itemCount > 0 && showCount >= itemCount) {
        const hideTeasers = matchedResults.slice(showCount);
        hideTeasers.forEach((teaser) => {
            teaser.closest("li").hidden = true;
        });

        moreButton.hidden = !hideTeasers.length;
    }
}

function updateMessage(count) {
    const { noResultsMessage } = elements;
    noResultsMessage.hidden = !!count > 0;
}

function filterByAll(config = {}) {
    const { reset } = config;
    const matchedResults = filterTeasersByTags(reset ? [] : getFilterValues());
    filterByCount(matchedResults);
    updateMessage(matchedResults.length);
}

function onChangeEvent() {
    form.addEventListener(
        "change",
        () => {
            filterByAll();
        },
        false
    );
}

function formReset() {
    form.addEventListener(
        "reset",
        () => {
            buttonApi.reset();
            filterByAll({ reset: true });
        },
        false
    );
}

function formSubmit() {
    const submitButton = form.querySelector("[type='submit']");
    // Remove submit button
    if (isElement(submitButton)) {
        submitButton.parentNode.removeChild(submitButton);
    }

    // Hijack standard form submit behaviour, to prevent accidental page refresh
    form.addEventListener(
        "submit",
        (e) => {
            e.preventDefault();
        },
        false
    );
}

export default (function createArticleResults() {
    if (!isElement(form) || !isElement(results)) {
        return;
    }

    elements = setElements();
    itemCount = Number(results.getAttribute("data-item-count"));

    buttonApi = (function moreButtonApi() {
        const { moreButton } = elements;
        const button = moreButton.querySelector("button");
        let counter = 1;

        function getCount() {
            return itemCount * counter;
        }

        button.addEventListener(
            "click",
            () => {
                counter += 1;
                filterByAll();
            },
            false
        );

        return {
            get: getCount,
            reset: () => {
                counter = 1;
                return counter;
            }
        };
    })();

    filterByAll();
    onChangeEvent();
    formReset();
    formSubmit();
})();
