import { isElement } from "./element";

export function isAuthor() {
    return !!document.querySelector("html[data-mode=author]");
}

export function isInternalLink(element) {
    if (!isElement(element)) {
        return false;
    }

    return !/^http(s?):/.test(element.getAttribute("href"));
}

export default {
    isAuthor,
    isInternalLink
};
