import events from "./publisherSubscriber";

const ACTIONS = {
    loaded: "/mediaquery/loaded",
    changed: "/mediaquery/changed",
    crossover: "/mediaquery/crossover"
};

const BREAKPOINTS = [
    // Narrowest 0 -> 374px
    { name: "NARROWEST", minWidth: 0 },
    // Narrow 375px -> 767px
    { name: "NARROW", minWidth: 23.4375 },
    // Medium 768px -> 1023px
    { name: "MEDIUM", minWidth: 48 },
    // Wide 1024px -> 1439px
    { name: "WIDE", minWidth: 64 },
    // Wider 1440px -> 1919px
    { name: "WIDER", minWidth: 90 },
    // Widest 1920px +
    { name: "WIDEST", minWidth: 120 }
];

const MEDIA_QUERIES = (function buildMQs() {
    return BREAKPOINTS.map((breakpoint, index) => {
        const maxWidth = BREAKPOINTS[index + 1] ? BREAKPOINTS[index + 1].minWidth - 0.001 : null;
        breakpoint.media = `(min-width: ${breakpoint.minWidth}em)`;
        if (maxWidth) {
            breakpoint.media += ` and (max-width: ${maxWidth}em)`;
        }
        return breakpoint;
    });
})();

export function getStatus() {
    return window.viewportSize;
}

function detectDesktop(value) {
    // A but dirty, but this adds a class to body if the device is atouch device,
    // allowing :hovers etc to be used, else a click will be used top open things like sub nav
    ("ontouchstart" in document.documentElement) ? document.querySelector('body').classList.add("touch-device") : document.querySelector('body').classList.remove("touch-device");

    return (
        String(value)
            .toUpperCase()
            .indexOf("WIDE") > -1
    );
}

function setStatus(newValue = "unset") {
    const status = String(newValue)
        .trim()
        .toUpperCase();

    const previous = getStatus() || null;
    const isDesktop = detectDesktop(status);
    const data = {
        status,
        previous,
        isDesktop
    };

    if (!isDesktop) {
        document.querySelectorAll('body')[0].classList.add('mobile');
    }

    if (previous && detectDesktop(previous) !== isDesktop) {
        // Crossed-over between 'desktop' & 'mobile' viewports
        events.publish(ACTIONS.crossover, data);
    }

    window.viewportSize = status;
    return data;
}

function addEventListener(e) {
    if (!e.matches) {
        return null;
    }

    return MEDIA_QUERIES.find((mediaQuery) => {
        if (mediaQuery.mql.matches) {
            events.publish(ACTIONS.changed, setStatus(mediaQuery.name));
            return mediaQuery;
        }
        return null;
    });
}

export function setMediaQueries() {
    MEDIA_QUERIES.map((mediaQuery) => {
        mediaQuery.mql = window.matchMedia(mediaQuery.media);
        mediaQuery.mql.addListener(addEventListener);
        if (!mediaQuery.mql.matches) {
            return "";
        }
        // Load event
        return events.publish(ACTIONS.loaded, setStatus(mediaQuery.name));
    });
}

export default {
    getStatus,
    setMediaQueries
};
