import { focusableElements, nodeListArray } from "./dom";
import { isElement } from "./element";

let firstTabStop;
let lastTabStop;
let target;

function detectTabKeyPress(e) {
    function jumpTabStop(watchFor, jumpTo) {
        if (document.activeElement === watchFor) {
            e.preventDefault();
            jumpTo.focus();
        }
    }

    // Detect only tab key code
    if (e.key.toUpperCase() !== "TAB") {
        return () => {};
    }

    // If shift key detected, user moving backwards
    return e.shiftKey ? jumpTabStop(firstTabStop, lastTabStop) : jumpTabStop(lastTabStop, firstTabStop);
}

function init() {
    const tabStops = nodeListArray(target.querySelectorAll(focusableElements)).filter((stop) => !stop.hidden);
    target.setAttribute("tabindex", -1);

    if (tabStops.length > 0) {
        [firstTabStop] = tabStops;
        lastTabStop = tabStops[tabStops.length - 1];
    }

    target.focus();
    target.addEventListener("keydown", detectTabKeyPress, false);
}

export default function tabTrap(targetSelector) {
    target = document.querySelector(targetSelector);

    if (!isElement(target)) {
        return {};
    }

    init();

    return (function tabTrapApi() {
        return {
            first: firstTabStop,
            last: lastTabStop,
            remove: function remove() {
                target.removeEventListener("keydown", detectTabKeyPress, false);
            }
        };
    })();
}
