(function featuredCarousel($) {
	
    if ($(".js-featured-carousal__w-mask").length != 0) {
        $(".js-featured-carousal__w-mask")
            .on("init", function (event, slick) {
                var data = slick.$slider.data();
                var arrowText = ".featured-carousal__arrow__text";
                slick.$prevArrow.find(arrowText).text(data.textPrevious);
                slick.$nextArrow.find(arrowText).text(data.textNext);
            })
            .slick({
                infinite: true,
                swipe: false,
                prevArrow:
                    ' <button class="featured-carousal__arrow featured-carousal__arrow--left"><i class="featured-carousal__arrow__icon icon-fsi icon-chevron-left"><span class="featured-carousal__arrow__text sr-only"></span></i></button>',
                nextArrow:
                    '<button class="featured-carousal__arrow featured-carousal__arrow--right"><i class="featured-carousal__arrow__icon icon-fsi icon-chevron-right"><span class="featured-carousal__arrow__text sr-only"></span></i></button>',
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1024, // tablet breakpoint
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 630, // mobile breakpoint
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
    }
})(jQuery);
