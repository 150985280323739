import { isAuthor } from "./aem";
import { getPosition, isElement } from "./element";

/**
 * Find out if current window is top.
 * Useful in handling `iframe`s
 */
export function isWindowTop() {
    return window.top === window;
}

export function isOverlay() {
    if (isAuthor() || isWindowTop()) {
        return false;
    }
    return document.querySelector("body").getAttribute("data-iframe") === "overlay";
}

/**
 * Add a page identifier for pages being presented in modal overlay
 * - CSS class, for styling hooks
 * - `data`-attribute for JavaScript hook
 */
export function overlayPresentation() {
    const body = document.querySelector("body");
    if (!isAuthor() && !isWindowTop()) {
        body.classList.add("in-modal");
        body.setAttribute("data-iframe", "overlay");
    }
}

/**
 * Check if visitor's browser settings indicate a preference for reduced motion (A11y).
 *
 * @note:
 * If browser doesn't recognize `prefers-reduced-motion` setting, we assume
 * no movement preferred (as a precaution). However, this means we always
 * return `true` on:
 * -- Edge (non-Chromium versions <= 45)
 * -- IE11
 * -- Samsung Internet
 *
 * For more information on `prefers-reduced-motion`, visit Mozilla Developer Network
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 */
export function prefersReducedMotion() {
    return !window.matchMedia("(prefers-reduced-motion: no-preference)").matches;
}

export function scrollToElementTop(targetElement, buffer = 0) {
    const siteHeader = document.querySelector(".site-header");

    if (!isElement(siteHeader) || !isElement(targetElement)) {
        return;
    }

    window.scrollTo(
        0,
        // Position calculation only required if site header floats (as on desktop),
        // otherwise scroll to targetElement top position is fine
        getPosition(targetElement).top -
            (window.getComputedStyle(siteHeader).position.toLowerCase() === "sticky"
                ? siteHeader.offsetHeight + buffer
                : buffer)
    );
}

export default {
    isOverlay,
    isWindowTop,
    overlayPresentation,
    prefersReducedMotion,
    scrollToElementTop
};
